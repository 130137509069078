<style lang="less" scoped src="./index.less"></style>
<template src="./index.html"></template>
<script>
export default {
  name: "progressBarPopup",
  data:()=>{
    return{
      show_popup: true,
    }
  },
  props:{
    data: 0,
  }
}
</script>